<template>
    <v-card color="grey lighten-4" elevation="0" class="cart-list-item">
        <v-card-text class="pa-3">
            <v-layout class="align-center">
                <v-flex grow-shrink-0 class="font-weight-medium font-size-14 font-size-md-16 grey--text text--darken-4">
                    <span v-if="cart.type == 'giftcard'">본품</span>
                    <template v-else>
                        <span>{{ cart.name }}</span>
                        <span v-if="siteInfo.orderType == `immediately` && [`excel`, `nanuri-excel`].includes(cart.platform)">{{ cart.stock && !cart.soldout ? `(${cart.stock}개)` : `(품절)` }}</span>
                    </template>
                </v-flex>
                <v-spacer />
                <v-flex grow-shrink-0 class="white">
                    <v-layout align-center class="controller">
                        <v-btn icon color="grey" class="pa-0" @click="cart.quantity--"><v-icon>mdi-minus</v-icon></v-btn>
                        <v-sheet class="d-flex align-center">
                            <v-text-field dense v-model="cart.quantity" hide-details solo flat class="v-number-field" />
                        </v-sheet>
                        <v-btn icon color="grey" class="pa-0" @click="cart.quantity++"><v-icon>mdi-plus</v-icon></v-btn>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: ["value"],
    data() {
        return {
            cart: this.$props.value,
        };
    },
    computed: {
        siteInfo() {
            return this.$store.state.siteInfo;
        },
    },
    watch: {
        cart: {
            deep: true,
            handler() {
                this.$emit("input", this.cart);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.v-number-field {
    font-size: 14px;
    padding-top: 0;
    ::v-deep {
        input {
            padding: 0 !important;
        }
    }
}
.controller {
    .v-btn,
    .v-sheet {
        width: 32px !important;
        height: 32px !important;
        min-width: 32px !important;
        ::v-deep {
            .v-input__control,
            .v-input__slot {
                min-height: 32px !important;
            }
            .v-input__slot {
                padding: 0 6px !important;
            }
        }
    }
}

@media (min-width: 768px) {
    .controller {
        .v-btn,
        .v-sheet {
            width: 40px !important;
            height: 40px !important;
            min-width: 40px !important;
            ::v-deep {
                .v-input__control,
                .v-input__slot {
                    min-height: 40px !important;
                }
            }
        }
    }
}
</style>
